@tailwind base;
@tailwind components;
@tailwind utilities;


body {
	margin: 0;
	/* font-family: 'Pretendard', 'Meiryo UI', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


code {
	/* font-family: 'Pretendard', 'Meiryo UI', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; */
}

*:lang(ko) {
  font-family : Pretendard !important
}

*:lang(ja) {
  font-family: 'Meiryo UI'!important;
}

*:lang(en) {
  font-family: Arial !important;
}

body:lang(ko) {
  font-family : Pretendard !important
}

body:lang(ja) {
  font-family: 'Meiryo UI'!important;
}

body:lang(en) {
  font-family: Arial !important;
}


code:lang(ko) {
  font-family : Pretendard !important
}

code:lang(ja) {
  font-family: 'Meiryo UI'!important;
}

code:lang(en) {
  font-family: Arial !important;
}

/*
body:lang(ja) {
  font-family: 'Meiryo UI'!important;
}

code:lang(ja) {
  font-family: 'Meiryo UI'!important;
}

body:lang(en) {
  font-family: 'Meiryo UI'!important;
}

code:lang(en) {
  font-family: 'Meiryo UI'!important;
} */